import styled from "styled-components";

import { backgroundColor, primaryColor } from "../../theme";

export const ServicesContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${backgroundColor};

  @media screen and (max-width: 1024px) {
    height: 1100px;
  }

  @media screen and (max-width: 768px) {
    height: 1650px;
  }
  @media screen and (max-width: 600px) {
    height: 1600px;
  }
  @media screen and (max-width: 480px) {
    height: 1700px;
  }
  @media screen and (max-width: 320px) {
    /* padding: 100px 0; */
    height: 1800px;
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 20px;
  padding: 0 10px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 25px;
    grid-gap: 30px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    /* height: 1300px; */
  }
  @media screen and (max-width: 320px) {
    /* padding: 100px 0; */
  }
`;

export const ServiceCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  min-height: ${({ large }) => (large ? "465px" : "350px")};
  max-height: 480px;
  /* padding: 30px; */
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-ease-in-out;

  &:hover {
    transform: scale(1.04);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ImgContainer = styled.div`
  border-radius: 10px 10px 0 0;
  height: 180px;
  width: 100%;
  overflow: hidden;
`;

export const ServiceImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const CardContent = styled.div`
  padding: 30px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ServicesTitle = styled.h2`
  font-size: 36px;
  color: ${primaryColor};
  margin-bottom: 64px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
  @media screen and (max-width: 600px) {
    font-size: 28px;
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
  @media screen and (max-width: 320px) {
    font-size: 18px;
  }
`;

export const ServiceTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 15px;
  color: ${primaryColor};
  /* text-align: center; */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
`;

export const ServicesContent = styled.p`
  text-align: justify;
  margin-bottom: 24px;
  font-size: 14px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
  @media screen and (max-width: 320px) {
    font-size: 10px;
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  z-index: 3;
  height: 600px;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 80px;
  padding: 40px 24px;
  justify-content: center;
  /* background: green; */

  @media screen and (max-width: 768px) {
    height: ${({ largeContent }) => (largeContent ? "950px" : "400px")};
  }
  @media screen and (max-width: 600px) {
    height: ${({ largeContent }) => (largeContent ? "1000px" : "350px")};
  }
  @media screen and (max-width: 480px) {
    height: ${({ largeContent }) => (largeContent ? "1050px" : "300px")};
  }
  @media screen and (max-width: 320px) {
    height: ${({ largeContent }) => (largeContent ? "1100px" : "250px")};
  }
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 600px;
  overflow: hidden;
  background: red;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
  /* background: ${backgroundColor};*/
  /* opacity: 1; */
`;

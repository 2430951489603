import React, { useState } from "react";

import emailjs from "emailjs-com";

import { useLoading, ThreeDots } from "@agney/react-loading";

import {
  ContactContainer,
  FormWraper,
  Form,
  Input,
  MessageArea,
  ButtonContainer,
  SubmitButton,
} from "./ContactElements";
import Alerta from "./Alerta";
import {
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  ImgWrap,
  Img,
  SubHeading,
} from "../InfoElements";

import ContactImg from "../../assets/images/contact_img.webp";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [fillAll, setFillAll] = useState(false);

  //spiner
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <ThreeDots width="40" />,
    loaderProps: {
      style: { color: "#b6c416" },
    },
  });

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const { name, email, message } = formData;

  const handleSubmit = (e) => {
    e.preventDefault();

    //validar campos
    if (name.trim() === "" || email.trim() === "" || message.trim() === "") {
      console.log("Todos los campos son obligatorios");
      setFillAll(true);
      setTimeout(() => {
        setFillAll(false);
      }, 3000);
      return;
    }

    //validar email
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email.trim())) {
      console.log("ingresa un email valido");
      setIncorrectEmail(true);
      setTimeout(() => {
        setIncorrectEmail(false);
      }, 3000);
      return;
    }

    setLoading(true);

    emailjs
      .sendForm(
        "ienergy_web",
        "email_de_contacto",
        e.target,
        "user_HGIcEO3ybbwD0aseViaDU"
      )
      .then(
        () => {
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          setLoading(false);
          setSendMessage(true);
          setTimeout(() => {
            setSendMessage(false);
          }, 3000);
        },
        (error) => {
          console.log(`error al enviar email: ${error}`);
          setLoading(false);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      );
  };

  return (
    <ContactContainer ligthBg={true} id="Contacto">
      <InfoWrapper>
        <InfoRow imgStart={false}>
          <Column1>
            <ImgWrap>
              <Img src={ContactImg} />
            </ImgWrap>
          </Column1>
          <Column2>
            <FormWraper>
              <SubHeading>¿Tienes un nuevo reto para nosotros?</SubHeading>
              <Form onSubmit={handleSubmit}>
                <Alerta
                  severity="error"
                  message="No se pudo enviar el mensaje"
                  estado={error}
                  setEstado={setError}
                ></Alerta>
                <Alerta
                  severity="error"
                  message="Email incorrecto"
                  estado={incorrectEmail}
                  setEstado={setIncorrectEmail}
                ></Alerta>
                <Alerta
                  severity="error"
                  message="Todos los campos son obligatorios"
                  estado={fillAll}
                  setEstado={setFillAll}
                ></Alerta>
                <Alerta
                  severity="success"
                  message="Mensaje enviado"
                  estado={sendMessage}
                  setEstado={setSendMessage}
                ></Alerta>
                {/* {error ? (
                  <Alerta
                    severity="error"
                    message="No se pudo enviar el mensaje"
                    estado={error}
                  ></Alerta>
                ) : null} */}
                <Input
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  onChange={updateInput}
                  value={formData.name || ""}
                />
                <Input
                  type="email"
                  name="email"
                  placeholder="Correo electrónico"
                  onChange={updateInput}
                  value={formData.email || ""}
                />
                <MessageArea
                  type="text"
                  name="message"
                  placeholder="Mensaje"
                  onChange={updateInput}
                  value={formData.message || ""}
                ></MessageArea>
                <ButtonContainer>
                  {loading ? (
                    <section {...containerProps}>{indicatorEl}</section>
                  ) : (
                    <SubmitButton
                      big={true}
                      dark={false}
                      type="submit"
                      // onClick={handleSubmit}
                    >
                      Enviar
                    </SubmitButton>
                  )}
                </ButtonContainer>
              </Form>
            </FormWraper>
          </Column2>
        </InfoRow>
      </InfoWrapper>
    </ContactContainer>
  );
};

export default ContactSection;

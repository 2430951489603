import React from "react";
import { FaTwitter, FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa";
// FaFacebook
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialIcons,
  SocialIconLink,
  WebsiteRights,
} from "../Footer/FooterElements";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Oficina Matriz</FooterLinkTitle>
                <FooterLink>
                  {/* Calle San Alejandro # 109 <br />
                  Fracc. Santa Elena C.P. 36790 <br /> */}
                  Salamanca, Guanajuato México. <br />
                  Tel. 464-126-11-31 <br /> grupo@ienergy.com.mx
                </FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Taller</FooterLinkTitle>
                <FooterLink>
                  Priv. Moroleon SN
                  <br />
                  Col. Constelación C.P. 36793
                  <br />
                  Salamanca, Guanajuato México.
                  <br />
                  Tel. 472-158-63-34
                </FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/ienergysa"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://twitter.com/IenergySA"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink
                  href="https://mx.linkedin.com/in/i-energy-natural-technology-66106298"
                  target="_blank"
                  aria-label="LinkedIn"
                >
                  <FaLinkedin />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.youtube.com/channel/UCXx38l3YqyFNxjWQf0FvlyA/videos"
                  target="_blank"
                  aria-label="Youtube"
                >
                  <FaYoutube />
                </SocialIconLink>
              </SocialIcons>
              <WebsiteRights>
                IEnergy © {new Date().getFullYear()}, Todos los derechos
                reservados.
              </WebsiteRights>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;

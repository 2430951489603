import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { isMobile } from "react-device-detect";

import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import NavBar from "../components/NavBar/NavBar";
import { servicesData } from "../data/services_data";
import {
  Column1,
  InfoContainer,
  InfoRow,
  InfoWrapper,
  TextWrapper,
  TopLine,
  Subtitle,
  Column2,
} from "../components/InfoElements";
import {
  VideoWrapper,
  Video,
  // VideoContainer,
} from "../components/ServicesSection/ServicesElements";
import ServiceImgSlider from "../components/ServiceImageSlider/Slider";

// import VideoSrc from "../assets/video/I_energy.mp4";

const ServiceDetailPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const { state } = useLocation();
  const id = state.id;
  const servicesList = servicesData[id].services;
  let menuNames = [];
  servicesList.forEach((name) => {
    menuNames.push(name.menuName);
  });

  return (
    <>
      <ScrollToTop />
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        isHome={false}
        linkList={menuNames}
      />
      <NavBar
        toggle={toggle}
        linkList={menuNames}
        isHome={false}
        isTransparent={false}
      />
      {id === 2 ? (
        <InfoContainer ligthBg={true}>
          <VideoWrapper>
            {/* <VideoContainer> */}
            <Video
              id="vid"
              autoPlay
              loop
              playsInline
              controls
              type="video/mp4"
              preload={"auto"}
              // onLoadedData={onLoadedData}
              // videoLoaded={true}
              // controls
            >
              {isMobile ? (
                <source
                  src="https://res.cloudinary.com/ienergy/video/upload/v1620256650/video/I_energy_th45dt.mp4"
                  type="video/mp4"
                />
              ) : (
                <source
                  src="https://res.cloudinary.com/ienergy/video/upload/v1618977960/video/i_energy_zkssle.mp4"
                  type="video/mp4"
                />
              )}
            </Video>
            {/* </VideoContainer> */}
          </VideoWrapper>
        </InfoContainer>
      ) : null}
      {servicesList.map((service, index) => {
        return (
          <InfoContainer
            ligthBg={service.servId % 2 === 0 ? true : false}
            id={service.menuName}
            key={index}
          >
            <InfoWrapper largeContent={service.largeContent}>
              <InfoRow imgStart={service.servId % 2 === 0 ? true : false}>
                <Column1>
                  <TextWrapper>
                    <TopLine>{service.title}</TopLine>
                  </TextWrapper>
                  <Subtitle
                    ligthBg={service.servId % 2 === 0 ? true : false}
                    largeContent={service.largeContent}
                  >
                    {service.description}
                  </Subtitle>
                </Column1>
                <Column2>
                  <ServiceImgSlider images={service.images} key={index} />
                </Column2>
              </InfoRow>
            </InfoWrapper>
          </InfoContainer>
        );
      })}
      <Footer />
    </>
  );
};

export default ServiceDetailPage;

import styled from "styled-components";
import { primaryColor } from "../../theme";

export const ClientsContainer = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-width: 1024px) {
    height: 400px;
  }

  @media screen and (max-width: 768px) {
    height: 400px;
  }
  @media screen and (max-width: 600px) {
    height: 350px;
  }
  @media screen and (max-width: 480px) {
    height: 300px;
  }
  @media screen and (max-width: 320px) {
    /* padding: 100px 0; */
    height: 300px;
  }
`;

export const ClientsTitle = styled.h2`
  color: ${primaryColor};
  /* font-size: 36px; */
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 1.4px;
  margin: 16px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    margin: 16px;
    margin-top: 20px;
  }
  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
`;

export const ClientSliderContainer = styled.div`
  max-height: 150px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  align-content: center;
  /* background: blue; */
`;

export const ImgWrap = styled.div`
  height: 100%;
  width: 250px;
  /* overflow: hidden; */
  padding-left: 25px;
  padding-right: 25px;

  /* background: green; */
  align-content: center;
`;

export const ClientImage = styled.img`
  height: 100px;
  width: 100%;

  object-fit: contain;
`;

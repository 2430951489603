import styled from "styled-components";
import { primaryColor } from "../../theme";

export const FooterContainer = styled.div`
  background-color: #101522;
`;
//rgb(14, 73, 82);
export const FooterWrap = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  /* background: blue; */
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  /* background: green; */

  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
    padding-top: 32px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const FooterLinksWrapper = styled.div`
  max-width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  align-items: stretch;
  grid-gap: 80px;
  padding: 0 10px;
  /* background: red; */

  /* @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  } */
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 25px;
    grid-gap: 10px;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  text-align: left;
  width: 200px;
  box-sizing: border-box;
  min-height: 80px;
  /* color: #fff; */
  /* background: violet; */

  @media screen and (max-width: 480px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h4`
  font-size: 14px;
  margin-bottom: 16px;
  color: ${primaryColor};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

export const FooterLink = styled.p`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0px;
  font-size: 12px;
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  margin-top: 10px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

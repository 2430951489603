import React, { useState } from "react";
import styled from "styled-components";

import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import NavBar from "../components/NavBar/NavBar";
import ScrollToTop from "../components/ScrollToTop";

import ProductsContent from "../components/ProductsSection/Content";
import ProductsBanner from "../components/ProductsSection/Banner";
import { productsList } from "../data/products";
// import { InfoContainer } from "../components/InfoElements";

//contenedor principal
const MainContainer = styled.div`
  color: #fff;
  background: #fff;
  padding: 90px 0;
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 320px) {
    /* padding: 100px 0; */
  }
`;

const ProductsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} isHome={false} linkList={[]} />
      <NavBar
        toggle={toggle}
        isHome={false}
        isTransparent={true}
        linkList={[]}
      />
      <MainContainer>
        <ProductsBanner />
        <ProductsContent productsList={productsList} />
      </MainContainer>
      <Footer />
    </>
  );
};

export default ProductsPage;

import React from "react";
import {
  SliderContainer,
  ImgSlider,
} from "../ServiceImageSlider/SliderElements";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { SRLWrapper } from "simple-react-lightbox";

// import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const ServiceImgSlider = ({ images }) => {
  return (
    <SliderContainer>
      <CarouselProvider
        naturalSlideHeight={100}
        naturalSlideWidth={120}
        // isIntrinsicHeight
        totalSlides={images.length}
        isPlaying={true}
        infinite={true}
        lockOnWindowScroll={true}
        touchEnabled={true}
        dragEnabled={true}
      >
        <Slider>
          <SRLWrapper
            options={{
              settings: {
                disableWheelControls: true,
                disableKeyboardControls: true,
                disablePanzoom: true,
              },
              buttons: {
                showAutoplayButton: false,
                showDownloadButton: false,
                showFullscreenButton: false,
                showNextButton: false,
                showPrevButton: false,
                showThumbnailsButton: false,
              },
              thumbnails: { showThumbnails: false },
            }}
          >
            {images.map((image, i) => {
              return (
                <Slide index={i}>
                  <ImgSlider src={image} />
                </Slide>
              );
            })}
          </SRLWrapper>
        </Slider>
      </CarouselProvider>
    </SliderContainer>
  );
};

export default ServiceImgSlider;

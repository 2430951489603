import React from "react";
import styled from "styled-components";

import { secondaryColor, primaryColor } from "../../theme";

const ProductLink = styled.a`
  color: ${secondaryColor};
  border-color: ${secondaryColor};

  &:hover {
    color: #fff;
    border-color: ${primaryColor};
    background: ${primaryColor};
  }
`;

const ProductCard = ({ product }) => {
  return (
    <div className="col-12 col-sm-6 mb-3 col-md-4 col-lg-3">
      <div className="card text-center">
        <div className="overflow">
          <img src={product.image} alt="" className="card-img-top" />
        </div>
        <div className="card-body text-dark">
          <h4 className="card-title h5"> {product.title} </h4>
          <p className="card-text h6">{product.description}</p>
          <p className="card-text h6">$ {product.price}</p>
          <ProductLink
            className="btn btn-outline-success"
            href={product.link}
            target="_blank"
          >
            Comprar
          </ProductLink>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

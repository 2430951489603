import React from "react";

import { primaryColor } from "../../theme";

const ProductsBanner = () => {
  return (
    <>
      <div className="container">
        <div
          className="card mb-4"
          style={{
            background: primaryColor,
            border: 0,
            alignItems: "center",
            padding: "0 0 0 0",
          }}
        >
          <div className="card-body text-center">
            <p className="card-title h5 text-dark">
              Se aceptan tarjetas de crédito y débito
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsBanner;

//lista de los servicios ofrecidos
export const servicesData = [
    {
        //Servicios de ingeniería a detalle
        "id": 0,
        "services": [
            {
                //Proyectos ejecutivos
                "servId": 1,
                "largeContent": false,
                "menuName" : "Proyectos ejecutivos",
                "title": "PROYECTOS EJECUTIVOS DE INSTALACIONES ELECTROMECANICAS",
                "description": "Permite a las empresas contar con información real de los materiales y equipos adecuados que debe de llevar su instalación eléctrica y mecánica",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618529292/Ing_detalle/proy_eje/1-min_bq5fsa.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618529310/Ing_detalle/proy_eje/2-min_z2rmec.webp",
                ]
            },
            {
                //Dieño y analisis mecanicos con elemento finito
                "servId": 2,
                "largeContent": false,
                "menuName" : "Diseño y análisis mécanicos con elemento finito",
                "title": "DISEÑO Y ANÁLISIS MÉCANICOS CON ELEMENTO FINITO",
                "description": "Análisis y diseño de piezas reales con el método de elemento finito, tomando las decisiones de modelado correctas para cada caso",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/1_njrryd.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/2_z5lcfs.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/3_boy2or.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/4_q1dzrk.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/5_pjzway.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/6_r3mbvo.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/7_l1g6ci.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/8_ieow9x.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/9_tr4nhe.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/10_l5eqg2.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958087/Ing_detalle/dis_analisis/11_nbroke.webp"
                ]
            },
            {
                //Analisis de fallas
                "servId": 3,
                "largeContent": false,
                "menuName" : "Análisis de fallas",
                "title": "ANÁLISIS DE FALLAS",
                "description": "Análisis de defectos en el diseño, material inadecuado, tratamiento térmico defectuoso, material mal trabajado, mecanizado defectuoso",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958455/Ing_detalle/analisis_fallas/1_hmhhci.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958454/Ing_detalle/analisis_fallas/2_cmrrah.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618958455/Ing_detalle/analisis_fallas/3_scqjvy.webp"
                ]
            }
        ]
    },
    {
        //Servicios de instalaciones industriales
        "id": 1,
        "services": [
            {
                //Instalaciones mecánicas
                "servId": 1,
                "largeContent": false,
                "menuName" : "Instalaciones mecánicas",
                "title": "INSTALACIONES MECÁNICAS",
                "description": "Diseño, fabricación e instalación de redes de tuberías para agua, aire, agua helada, agua caliente, vapor, productos químicos, condensados, drenajes, gas LP o natural, etc., sistemas completos de agua de enfriamiento, aire comprimido en baja y alta presión, generación de vapor, tratamiento de aguas, chillers, recubrimiento aislante de tuberías, tanques y reactores., estructuras metálicas soldables y atornillables",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618530949/inst_indust/inst_mec/1_it4fss.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618530944/inst_indust/inst_mec/2_hy23zk.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618530949/inst_indust/inst_mec/3_s3acfz.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618530950/inst_indust/inst_mec/4_yc4hb5.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618530948/inst_indust/inst_mec/5_frc629.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618530944/inst_indust/inst_mec/6_rwz5kw.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618530946/inst_indust/inst_mec/7_pebhtb.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618531149/inst_indust/inst_mec/8_y4hyhx.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618531148/inst_indust/inst_mec/9_yspavg.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618531147/inst_indust/inst_mec/10_qnyqzb.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618531147/inst_indust/inst_mec/11_xlwcu0.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618531147/inst_indust/inst_mec/12_k0smnh.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618531148/inst_indust/inst_mec/13_izljrj.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618959138/inst_indust/inst_mec/14_ezg66x.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618959120/inst_indust/inst_mec/15_ulwtxv.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618959121/inst_indust/inst_mec/16_tfp46s.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618959123/inst_indust/inst_mec/17_q3wjuc.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618959124/inst_indust/inst_mec/18_ynk6nh.webp"

                ]
            },
            {
                //Sistemas de control
                "servId": 2,
                "largeContent": false,
                "menuName": "Sistemas de control",
                "title": "SISTEMAS DE CONTROL",
                "description": "Uno de los recursos más utilizados en el sector industrial es el sistema de control. Toda producción liderada por ingeniería requiere de este proceso para lograr objetivos determinados. La función de éste es la de gestionar o regular la forma en que se comporta otro sistema para así evitar fallas. El sistema de control de procesos está formado por un conjunto de dispositivos de diverso orden. Pueden ser de tipo eléctrico, neumático, hidráulico, mecánico, entre otros, todos ellos, determinados por el objetivo a alcanzar",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532402/inst_indust/sis_cont/1_kiybrc.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532399/inst_indust/sis_cont/2_ciwr7j.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532403/inst_indust/sis_cont/3_cmwf9y.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532399/inst_indust/sis_cont/4_ua5f18.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532400/inst_indust/sis_cont/5_j0eka7.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532398/inst_indust/sis_cont/6_qfjecq.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532399/inst_indust/sis_cont/7_e8pqwg.webp"

                ],
            },
            {
                //Baja y media tensión
                "servId": 3,
                "largeContent": false,
                "menuName" : "Baja y media tensión",
                "title": "INSTALACIONES ELÉCTRICAS BAJA Y MEDIA TENSIÓN",
                "description": "Instalación de subestaciones, circuitos de fuerza, alumbrado, contactos, pararrayos y tierras físicas, canalizaciones con tubería, ducto y charola, instalaciones subterráneas con tubería y encofradas, instalación de electroductos",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532945/inst_indust/ins_ele_baja_alta/1_ythlrz.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532946/inst_indust/ins_ele_baja_alta/2_xjix6v.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532946/inst_indust/ins_ele_baja_alta/3_eu3yed.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618532946/inst_indust/ins_ele_baja_alta/4_gwq1t9.webp"
                    
                ]
            },
            {
                //Estructuras
                "servId": 4,
                "largeContent": false,
                "menuName" : "Estructuras",
                "title": "ESTRUCTURAS",
                "description": "Realizamos servicios de montaje, reparación y protección de estructuras. Estos servicios son realizados por equipos multidisciplinarios de Técnicos Certificados y con vasta experiencia en la ejecución y evaluación, en conformidad con la legislación y códigos aplicables. El desarrollo continuo de nuevos métodos y técnicas nos permite presentar un servicio integrado y a la medida de la necesidad, calidad y seguridad de la instalación.",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533409/inst_indust/estructuras/1_d0f1sz.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533410/inst_indust/estructuras/2_qcknhn.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533407/inst_indust/estructuras/3_of78u1.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533407/inst_indust/estructuras/4_wenho9.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533407/inst_indust/estructuras/5_y7wwyy.webp"
                ]
            },
            {
                //AC y Ventilación
                "servId": 5,
                "largeContent": false,
                "menuName" : "AC y Ventilación",
                "title": "AIRE ACONDICIONADO Y VENTILACIÓN",
                "description": "Soluciones de aire acondicionado industrial y climatización para edificios y naves industriales. Instalación y mantenimiento de equipos de aire acondicionado industrial.​ En IENERGY somos especialistas y contamos con la experiencia en el diseño, montaje y mantenimiento de instalaciones y equipos de aire acondicionado y climatización industrial",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533855/inst_indust/ac_vent/1_vxa1vq.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533849/inst_indust/ac_vent/2_whjdl8.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533849/inst_indust/ac_vent/3_q8nynd.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533849/inst_indust/ac_vent/4_akmwov.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533850/inst_indust/ac_vent/5_zxvevt.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533850/inst_indust/ac_vent/6_isw2sq.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618533849/inst_indust/ac_vent/7_uhuilh.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618959681/inst_indust/ac_vent/8_fqhejf.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618959681/inst_indust/ac_vent/9_xsknqz.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618959682/inst_indust/ac_vent/10_oprom6.webp"
                ]
            },
            {
                //Sistemas contra incendios
                "servId": 6,
                "largeContent": true,
                "menuName" : "Sistemas contra incendios",
                "title": "SISTEMAS CONTRA INCENDIOS",
                "description": "Manejamos el suministro e instalación de cualquier componente del sistema de protección, alarma y detección de incendios. Al final de la instalación realizamos las pruebas indicadas en la NFPA para asegurar el correcto funcionamiento del sistema. Garantizamos que la ingeniería desarrollada así como las instalaciones que se realicen cumplirán con la normatividad existente y serán aprobadas por su compañía de seguros. Ejecutamos la obra civil complementaria del sistema contra incendio como construcción de cuartos de bombas, cimentaciones de tanques, cisternas, atraques de tuberías, pasos de muros, etc. Suministramos e instalamos equipos de bombeo con motor eléctrico y diésel, bombas Jockey, tuberías e instrumentos, tanques atornillables para el almacenamiento de agua, rociadores automáticos (sprinklers), redes contra incendio, hidrantes, gabinetes, cañones o monitores, tomas siamesas, risers, sistemas de diluvio, esferas, etc.",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535273/inst_indust/sis_incendio/1_wvdnu3.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535273/inst_indust/sis_incendio/2_lprijg.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535273/inst_indust/sis_incendio/3_spt620.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535263/inst_indust/sis_incendio/4_tbsn7z.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535264/inst_indust/sis_incendio/5_yjgm70.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535263/inst_indust/sis_incendio/6_ean9ik.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535272/inst_indust/sis_incendio/7_x7z3xw.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535278/inst_indust/sis_incendio/8_hiy578.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535282/inst_indust/sis_incendio/9_bqwrnx.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961381/inst_indust/sis_incendio/10_igbrya.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961381/inst_indust/sis_incendio/11_rtjb5j.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961376/inst_indust/sis_incendio/12_ma8w1k.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961369/inst_indust/sis_incendio/13_zdjow8.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961366/inst_indust/sis_incendio/14_ir4vg9.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961373/inst_indust/sis_incendio/15_sutlrg.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961367/inst_indust/sis_incendio/16_gatqos.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961369/inst_indust/sis_incendio/17_ylwxak.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961371/inst_indust/sis_incendio/18_jud7tl.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961374/inst_indust/sis_incendio/19_j6rb4t.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961358/inst_indust/sis_incendio/20_mmh6qb.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961362/inst_indust/sis_incendio/21_lajato.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961359/inst_indust/sis_incendio/22_ja1rrl.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961360/inst_indust/sis_incendio/23_ri1mbh.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961359/inst_indust/sis_incendio/24_hfcvpz.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618961363/inst_indust/sis_incendio/25_pl0ihv.webp",

                ]
            },
            {
                //Obra civil
                "servId": 7,
                "largeContent": false,
                "menuName" : "Obra civil",
                "title": "OBRA CIVIL",
                "description": "Realizamos remodelaciones, ampliaciones y cimentación para equipos",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535772/inst_indust/obra_civil/1_b1owmv.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535768/inst_indust/obra_civil/2_nsslyq.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535769/inst_indust/obra_civil/3_mwdjdb.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535769/inst_indust/obra_civil/4_udgusl.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618535770/inst_indust/obra_civil/5_jubraa.webp"
                ]
            }
        ]
    },
    {
        //Servicios de sistemas energeticos renovables
        "id": 2,
        "services": [
            {
                //Sistemas fotovoltaicos
                "servId": 1,
                "largeContent": false,
                "menuName" : "Sistemas fotovoltaicos",
                "title": "SISTEMAS FOTOVOLTAICOS",
                "description": "Diseño del circuito eléctrico, venta e instalación de sistemas fotovoltaicos residenciales e industriales",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618536628/sis_energ/sis_fot/1_lmebrp.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618536623/sis_energ/sis_fot/2_qratqn.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618536624/sis_energ/sis_fot/3_lqt2rh.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618536623/sis_energ/sis_fot/4_ucf0gi.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618536624/sis_energ/sis_fot/5_q7x10e.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618536625/sis_energ/sis_fot/6_ajarxh.webp",
                ]
            },
            {
                //Refrigeraciónpor absorción
                "servId": 2,
                "largeContent": false,
                "menuName" : "Refrigeración por absorción",
                "title": "INSTALACIÓN DE REFRIGERACIÓN POR ABSORCIÓN",
                "description": "Los sistemas de refrigeración por absorción utilizan como equipo para producir frío, una máquina de absorción, que es un equipo que permite obtener bajas temperaturas para todo tipo de aplicaciones, pero usando agua caliente o calor como alimentación, frente a un sistema convencional que usa electricidad",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1618536994/sis_energ/ref_abs/1_mcfnxt.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1618536993/sis_energ/ref_abs/2_tkdgso.webp",
                ]
            },
            {
                //Sistemas de cogeneracion
                "servId": 3,
                "largeContent": false,
                "menuName" : "Sistemas de cogeneracion",
                "title": "SISTEMAS DE COGENERACIÓN",
                "description": "La cogeneración es el procedimiento mediante el cual se obtiene simultáneamente energía eléctrica y energía térmica útil",
                "images": [
                    "https://res.cloudinary.com/ienergy/image/upload/v1620249456/sis_energ/sis_cogeneracion/serImg54_dsiq1m.webp",
                    "https://res.cloudinary.com/ienergy/image/upload/v1620249490/sis_energ/sis_cogeneracion/serImg55_tlp9ac.webp",
                ]
            }
        ]
    }
]



import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
  SubHeading,
} from "../InfoElements";

import ImgAboutUs from "../../assets/images/about-us-img.webp";

const AboutSection = () => {
  const ligthBg = true;
  return (
    <>
      <InfoContainer ligthBg={ligthBg} id="Nosotros">
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <TextWrapper>
                <TopLine>Nosotros</TopLine>
                <Heading ligthBg={ligthBg}>
                  Somos una compañía de servicios especializados de ingeniería
                </Heading>
                <SubHeading>Nuestra visión</SubHeading>
                <Subtitle ligthBg={ligthBg}>
                  Ser una empresa Mexicana con presencia internacional que
                  optimice los procesos de producción para las industrias
                  pública y privada.
                </Subtitle>
                <SubHeading>Nuestra misión</SubHeading>
                <Subtitle ligthBg={ligthBg}>
                  Desarrollar conocimiento tecnológico-multidisciplinario para
                  proveer soluciones que cumplan con las necesidades de nuestros
                  clientes, siendo socialmente responsable y amigable con el
                  medio ambiente.
                </Subtitle>
                <SubHeading>Nuestros valores</SubHeading>
                <Subtitle ligthBg={ligthBg}>
                  Respeto, calidad, innovación, responsabilidad y liderazgo.
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={ImgAboutUs} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default AboutSection;

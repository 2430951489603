import styled from "styled-components";
import { backgroundColor, primaryColor, secondaryColor } from "../theme";

//contenedor principal
export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ ligthBg }) => (ligthBg ? "#fff" : backgroundColor)};
  padding: 50px 0;
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 320px) {
    /* padding: 100px 0; */
  }
`;

//contenedor de las 2 columnas
export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 800px;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  /* background: green; */

  @media screen and (max-width: 768px) {
    height: ${({ largeContent }) => (largeContent ? "950px" : "800px")};
  }
  @media screen and (max-width: 600px) {
    height: ${({ largeContent }) => (largeContent ? "1000px" : "800px")};
  }
  @media screen and (max-width: 480px) {
    height: ${({ largeContent }) => (largeContent ? "1050px" : "800px")};
  }
  @media screen and (max-width: 320px) {
    height: ${({ largeContent }) => (largeContent ? "1100px" : "800px")};
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.h2`
  color: ${secondaryColor};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
  @media screen and (max-width: 320px) {
    font-size: 10px;
  }
`;

export const Heading = styled.h3`
  margin-bottom: 35px;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ ligthBg }) => (ligthBg ? "#010606" : "#f7f8fa")};

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
  @media screen and (max-width: 600px) {
    font-size: 28px;
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
  @media screen and (max-width: 320px) {
    font-size: 18px;
  }
`;

export const SubHeading = styled.h4`
  color: ${primaryColor};
  font-size: 20px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 12px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ ligthBg }) => (ligthBg ? backgroundColor : "#fff")};

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media screen and (max-width: 768px) {
    font-size: ${({ largeContent }) => (largeContent ? "14px" : "16px")};
  }
  @media screen and (max-width: 600px) {
    font-size: ${({ largeContent }) => (largeContent ? "12px" : "14px")};
  }
  @media screen and (max-width: 480px) {
    font-size: ${({ largeContent }) => (largeContent ? "11px" : "12px")};
  }
  @media screen and (max-width: 320px) {
    font-size: ${({ largeContent }) => (largeContent ? "10px" : "12px")};
  }
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  /* background: red; */
  overflow: hidden;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  alertContainer: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "0px",
  },
  closeContainer: {
    margin: "0px",
  },
}));

const Alerta = ({
  message,
  severity,
  estado,
  setEstado,
  variante = "standard",
}) => {
  const classes = useStyles();

  return (
    <div className={estado ? classes.alertContainer : classes.closeContainer}>
      <Collapse in={estado}>
        <Alert
          severity={severity}
          variant={variante}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setEstado(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  );
};

export default Alerta;

import styled from "styled-components";

export const SliderContainer = styled.div`
  max-width: 555px;
  max-height: 480px;
  /* height: 400px; */
  /* display: flex; */
  padding: 10px;
  align-items: center;
  /* background: red; */

  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
    height: 440px;
  }
  @media screen and (max-width: 600px) {
    height: 340px;
  }
  @media screen and (max-width: 540px) {
    height: 380px;
  }
  @media screen and (max-width: 480px) {
    height: 280px;
  }
  @media screen and (max-width: 320px) {
    height: 200px;
  }
`;

export const SlideWrapper = styled.div`
  /* max-width: 555px;*/
  height: 100%;
  /* min-width: 100%; */
  /* background: blue; */
  /* overflow: hidden; */
  align-items: center;
`;

export const ImgSlider = styled.img`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;

export const ControlButton = styled.button`
  position: absolute;
  top: 50%;
  left: 0;
  transform: ${({ left }) => (left ? "translateY(-50%)" : "translateY(50%)")};
`;

import React, { useState } from "react";

import Sidebar from "../components/Sidebar/Sidebar";
import NavBar from "../components/NavBar/NavBar";
import HeroSection from "../components/HeroSection/Hero";
import AboutSection from "../components/AboutSection/About";
import ServicesSection from "../components/ServicesSection/Services";
import ClientsSection from "../components/ClientsSection/Clients";
import ContactSection from "../components/ContactSection/Contact";
import Footer from "../components/Footer/Footer";

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        isHome={true}
        linkList={[
          "Nosotros",
          "Servicios",
          "Productos",
          "Clientes",
          "Contacto",
        ]}
      />
      <NavBar
        toggle={toggle}
        linkList={[
          "Nosotros",
          "Servicios",
          "Productos",
          "Clientes",
          "Contacto",
        ]}
        isHome={true}
        isTransparent={true}
      />
      <HeroSection />
      <AboutSection />
      <ServicesSection />
      <ClientsSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default HomePage;

import styled from "styled-components";
import { backgroundColor, primaryColor, secondaryColor } from "../../theme";

export const ContactContainer = styled.div`
  background: ${({ ligthBg }) => (ligthBg ? "#fff" : backgroundColor)};
  padding: 20px;
  height: 800px;

  @media screen and (max-width: 768px) {
    height: 1000px;
    padding: 10px;
  }
  @media screen and (max-width: 600px) {
    height: 920px;
    padding: 0px;
  }
  @media screen and (max-width: 480px) {
    height: 850px;
    padding: 0px;
  }
  @media screen and (max-width: 320px) {
    padding: 0px;
    height: 800px;
  }
`;

export const FormWraper = styled.div`
  max-width: 560px;
  padding-top: 10px;
  /* padding-bottom: 60px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: red; */

  @media screen and (max-width: 480px) {
    padding: 2px;
  }

  @media screen and (max-width: 320px) {
    padding: 0px;
  }
`;

export const Form = styled.form`
  /* display: table; */
  margin: 40px auto;
  width: 100%;

  /* background: blue; */
  /* @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 300px;
  }
  @media screen and (max-width: 320px) {
    width: 250px;
  } */
`;

export const Input = styled.input`
  font-size: 18px;
  box-sizing: border-box;
  display: block;
  border-radius: 15px;
  border-width: 1px;
  border-color: ${secondaryColor};
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  outline: none;
  border-style: solid;

  &:focus {
    border-width: 2px;
    border-radius: 15px;
    border-color: ${primaryColor};
  }

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding: 8px;
  }
  @media screen and (max-width: 320px) {
    font-size: 14px;
    padding: 6px;
  }
`;

export const MessageArea = styled.textarea`
  font-size: 18px;
  box-sizing: border-box;
  display: block;
  border-radius: 15px;
  border-width: 1px;
  border-color: ${secondaryColor};
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  outline: none;
  border-style: solid;
  height: 200px;

  &:focus {
    border-width: 2px;
    border-radius: 15px;
    border-color: ${primaryColor};
  }

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding: 8px;
    height: 180px;
  }
  @media screen and (max-width: 320px) {
    font-size: 14px;
    height: 160px;
    padding: 6px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 40px;
  justify-content: center;
  /* background: green; */

  @media screen and (max-width: 320px) {
    padding: 0 20px;
  }
`;

export const SubmitButton = styled.button`
  border-radius: 50px;
  background: ${primaryColor};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 18px" : "10px 20px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontbig }) => (fontbig ? "18px" : "14px")};
  outline: none;
  border: none;
  cursor: pointer;
  flex: 1 1 auto;
  /* display: flex;
  flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${secondaryColor};
  }
`;

export const Alert = styled.p`
  background-color: red;
  color: white;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
`;

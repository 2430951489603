import React from "react";

import {
  ClientsContainer,
  ClientsTitle,
  ClientSliderContainer,
  ClientImage,
  ImgWrap,
} from "./ClientsElements";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { clientsImg } from "../../data/clients";
// const imageUrl = require.context("../../assets/images/clients", true);

const ClientsSection = () => {
  return (
    <ClientsContainer id="Clientes">
      <ClientsTitle>Ellos han confiado en nosotros</ClientsTitle>
      <ClientSliderContainer>
        <Slider
          dots={false}
          infinite={true}
          speed={1000}
          slidesToShow={6}
          slidesToScroll={6}
          autoplay={true}
          autoplaySpeed={2500}
          lazyLoad="ondemand"
          centerMode={true}
          arrows={false}
          swipe={true}
          swipeToSlide={true}
          pauseOnHover={false}
          draggable={false}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                autoplay: true,
                autoplaySpeed: 2000,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                autoplay: true,
                autoplaySpeed: 2000,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                autoplay: true,
                autoplaySpeed: 1500,
              },
            },
            {
              breakpoint: 320,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 1250,
              },
            },
          ]}
        >
          {clientsImg.map((img, i) => {
            return (
              <ImgWrap>
                <ClientImage
                  key={i}
                  // src={imageUrl(`./${img}.webp`).default}
                  src={img}
                ></ClientImage>
              </ImgWrap>
            );
          })}
        </Slider>
      </ClientSliderContainer>
    </ClientsContainer>
  );
};

export default ClientsSection;

import React from "react";

import ProductCard from "./Card";

const ProductsContent = ({ productsList }) => {
  return (
    <>
      <div className="container">
        <div className="row">
          {productsList.map((product, index) => {
            return <ProductCard product={product} key={index} />;
          })}
        </div>
      </div>
    </>
  );
};

export default ProductsContent;

//lista de las imagenes de los clientes
export const clientsImg = [
    "https://res.cloudinary.com/ienergy/image/upload/v1618537772/clients/client1_qdsscy.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537772/clients/client2_vw5vej.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537772/clients/client3_eivnuh.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537772/clients/client4_spe2cx.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537772/clients/client5_w17m6u.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537772/clients/client6_zad8sf.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client7_y48xbp.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client8_zdqgv6.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client9_l2ym5x.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client10_j2yfxm.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client11_hhe9a9.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client12_ethsfj.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client13_ley33n.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client14_ekunax.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client15_ul49ed.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client16_hjxq46.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client17_p6et5h.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client18_ox0rgh.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client19_dger0y.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client20_w3wudw.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client21_vl2pdz.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client22_wg7ozx.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537773/clients/client23_ppbvpl.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537774/clients/client24_h9jsy3.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618537774/clients/client25_cv3who.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618542140/clients/client26_pmjgfc.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618542140/clients/client27_feoabr.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618542140/clients/client28_syziax.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618542524/clients/client29_uvktcy.webp",
    "https://res.cloudinary.com/ienergy/image/upload/v1618962554/clients/client30_xn1ao1.webp"
]
import React from "react";
import { animateScroll as scroll } from "react-scroll";

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLinkS,
  SidebarLinkR,
  ExternalSidebarLink,
} from "./SidebarElements";
import { activateProducts } from "../../data/products";

const Sidebar = ({ isOpen, toggle, linkList, isHome }) => {
  const toggleHome = () => {
    toggle();
    scroll.scrollToTop();
  };
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {isHome ? (
            <SidebarLinkS
              onClick={toggleHome}
              smooth={true}
              duration={500}
              spy={true}
              exact={true}
              offset={-80}
            >
              Inicio
            </SidebarLinkS>
          ) : null}
          {linkList.map((link) => {
            return link === "Productos" ? (
              activateProducts === true ? (
                <SidebarLinkR to="/products">{link}</SidebarLinkR>
              ) : (
                <ExternalSidebarLink
                  href="https://listado.mercadolibre.com.mx/_CustId_331307907"
                  target="_blank"
                >
                  {link}
                </ExternalSidebarLink>
              )
            ) : (
              <SidebarLinkS
                key={link}
                to={link}
                onClick={toggle}
                smooth={true}
                duration={500}
                spy={true}
                exact={true}
                offset={-80}
              >
                {link}
              </SidebarLinkS>
            );
          })}
          {/* <SidebarLinkS
            to="nosotros"
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact={true}
            offset={-80}
          >
            Nosotros
          </SidebarLinkS> */}
          {/* <SidebarLinkS
            to="servicios"
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact={true}
            offset={-80}
          >
            Servicios
          </SidebarLinkS> */}
          {/* <SidebarLinkS
            to="productos"
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact={true}
            offset={-80}
          >
            Productos
          </SidebarLinkS> */}
          {/* <SidebarLinkS
            to="clientes"
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact={true}
            offset={-80}
          >
            Clientes
          </SidebarLinkS> */}
          {/* <SidebarLinkS
            to="contacto"
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact={true}
            offset={-80}
          >
            Contacto
          </SidebarLinkS> */}
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;

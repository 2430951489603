import React from "react";
import {
  ServicesContainer,
  ServicesTitle,
  ServicesWrapper,
  ServiceCard,
  ServiceImg,
  ServiceTitle,
  ServicesContent,
  ImgContainer,
  CardContent,
} from "./ServicesElements";
import { ButtonComponent } from "../ButtonComponent";

// import Service1Img from "../../assets/images/service1.webp";
// import Service2Img from "../../assets/images/service2.webp";
// import Service3Img from "../../assets/images/service3.webp";

const ServicesSection = () => {
  return (
    <ServicesContainer id="Servicios">
      <ServicesTitle> Nuestros servicios</ServicesTitle>
      <ServicesWrapper>
        <ServiceCard large={true}>
          <ImgContainer>
            <ServiceImg src="https://res.cloudinary.com/ienergy/image/upload/v1618529292/Ing_detalle/proy_eje/1-min_bq5fsa.webp" />
          </ImgContainer>
          <CardContent>
            <ServiceTitle>Ingeniería a detalle</ServiceTitle>
            <ServicesContent>
              Definir con precisión la ejecución del proyecto, de manera que los
              documentos que lo desarrollan sean suficientes para llevarlo a la
              práctica, ya sea bajo la dirección de los mismos proyectistas o
              por otro equipo de ingeniería distinto.
            </ServicesContent>
            <ButtonComponent
              dark={false}
              fontBig={false}
              big={false}
              to={{
                pathname: "/service",
                state: {
                  id: 0,
                },
              }}
            >
              Ver más
            </ButtonComponent>
          </CardContent>
        </ServiceCard>
        <ServiceCard large={true}>
          <ImgContainer>
            <ServiceImg src="https://res.cloudinary.com/ienergy/image/upload/v1618530944/inst_indust/inst_mec/2_hy23zk.webp" />
          </ImgContainer>
          <CardContent>
            <ServiceTitle>Instalaciones industriales</ServiceTitle>
            <ServicesContent>
              Servicio completo y personalizado para cualquier tipo de
              instalación industrial, desde el asesoramiento previo a cualquier
              proyecto, diseño y montaje, hasta el mantenimiento de las
              instalaciones y maquinaria.
            </ServicesContent>
            <br />
            <ButtonComponent
              dark={false}
              fontBig={false}
              big={false}
              to={{
                pathname: "/service",
                state: {
                  id: 1,
                },
              }}
            >
              Ver más
            </ButtonComponent>
          </CardContent>
        </ServiceCard>
        <ServiceCard large={true}>
          <ImgContainer>
            <ServiceImg src="https://res.cloudinary.com/ienergy/image/upload/v1618536628/sis_energ/sis_fot/1_lmebrp.webp" />
          </ImgContainer>
          <CardContent>
            <ServiceTitle>Sistemas energeticos renovables</ServiceTitle>
            <ServicesContent>
              Realizamos la venta e instalación de sistemas fotovoltáicos,
              instalación de refrigeración por absorcion y sistemas de
              cogeneración
              <br />
              <br />
              <br />
            </ServicesContent>
            <ButtonComponent
              dark={false}
              fontbig={false}
              big={false}
              to={{
                pathname: "/service",
                state: {
                  id: 2,
                },
              }}
            >
              Ver más
            </ButtonComponent>
          </CardContent>
        </ServiceCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default ServicesSection;

import styled from "styled-components";

import { Link as LinkR } from "react-router-dom";

import { primaryColor, secondaryColor } from "../theme";

export const ButtonComponent = styled(LinkR)`
  border-radius: 50px;
  background: ${primaryColor};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 18px" : "10px 20px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontbig }) => (fontbig ? "18px" : "14px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  max-width: 100px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${secondaryColor};
    text-decoration: none;
    color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  }
`;

import React, { useState } from "react";
import {
  HeroContainer,
  VideoContainer,
  VideoThumbnail,
  VideoBg,
  HeroContent,
  Title,
  Subtitle,
  LogoImg,
  //BackgroundFilter,
} from "./HeroElements";

// import Video from "../../assets/video/backgroundVideo.mp4";
import Logo from "../../assets/images/logo.webp";
import BgImage from "../../assets/images/header-background.webp";

const HeroSection = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const onLoadedData = () => {
    setVideoLoaded(true);
  };

  return (
    <HeroContainer id="home">
      <VideoContainer videoLoaded={videoLoaded}>
        <VideoThumbnail src={BgImage} videoLoaded={videoLoaded} />
        <VideoBg
          autoPlay
          loop
          playsInline
          muted
          src="https://res.cloudinary.com/ienergy/video/upload/v1619545306/video/ienergy_nxz0lk.mp4"
          type="video/mp4"
          preload={"auto"}
          onLoadedData={onLoadedData}
          videoLoaded={videoLoaded}
        />
      </VideoContainer>
      <HeroContent>
        <LogoImg src={Logo} />
        <Title>Servicios especializados de ingeniería</Title>
        <Subtitle>La mejor opción del Bajio</Subtitle>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;

import styled from "styled-components";
import { backgroundColor, primaryColor } from "../../theme";

export const HeroContainer = styled.div`
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 30px; */
  height: 100vh;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const VideoThumbnail = styled.img`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: ${({ videoLoaded }) => (videoLoaded ? 0 : 1)};
  transition: opacity 400ms ease 0ms;
  background: ${backgroundColor};
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100vh;
  -o-object-fit: fill;
  object-fit: fill;
  background: ${backgroundColor};
  opacity: ${({ videoLoaded }) => (videoLoaded ? 1 : 0)};

  @media screen and (max-width: 768px) {
    -o-object-fit: fill;
    object-fit: fill;
  }
  @media screen and (max-width: 600px) {
    -o-object-fit: cover;
    object-fit: cover;
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

// export const BackgroundFilter = styled.div`
//   z-index: 3;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(73, 75, 75, 0.336);
//   display: flex;
//   position: relative;
//   flex-direction: column;
//   align-items: center;
// `;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  top: 25%;
  flex-direction: column;
  align-items: center;
`;

export const LogoImg = styled.img`
  width: 18em;
  height: 12em;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 45px;
  margin: 0;
  line-height: 1.5;
  font-weight: 600;
  color: ${primaryColor};
  text-align: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 30px;
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    font-size: 22px;
    text-align: center;
  }
`;

export const Subtitle = styled.h2`
  font-size: 30px;
  margin: 0;
  line-height: 1.5;
  font-weight: 400;
  color: #e6e4e4;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media screen and (max-width: 768px) {
    font-size: 26px;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 22px;
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    font-size: 16px;
    text-align: center;
  }
`;

import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import { useHistory } from "react-router-dom";

import {
  Nav,
  NavbarContainer,
  NavLogoContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinksS,
  NavLinksR,
  ExternalLink,
  // NavLinksR,
} from "./NavbarElements";
import LogoImage from "../../assets/images/logo.webp";
import { activateProducts } from "../../data/products";

const NavBar = ({ toggle, isHome, linkList, isTransparent }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeBgNav = () => {
    if (window.scrollY >= 70) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBgNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Nav scrollNav={scrollNav} isTransparent={isTransparent}>
        <NavbarContainer>
          <NavLogoContainer to="/" onClick={isHome ? toggleHome : goBack}>
            <NavLogo src={LogoImage} />
          </NavLogoContainer>
          {linkList.length === 0 ? null : (
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
          )}
          <NavMenu>
            {linkList.map((link) => {
              return (
                <NavItem key={link}>
                  {link === "Productos" ? (
                    activateProducts === true ? (
                      <NavLinksR to="/products">{link}</NavLinksR>
                    ) : (
                      <ExternalLink
                        href="https://listado.mercadolibre.com.mx/_CustId_331307907"
                        target="_blank"
                      >
                        {link}
                      </ExternalLink>
                    )
                  ) : (
                    <NavLinksS
                      to={link}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact={true}
                      offset={-80}
                    >
                      {link}
                    </NavLinksS>
                  )}
                </NavItem>
              );
            })}
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default NavBar;

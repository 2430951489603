export const activateProducts = false;

export const productsList = [
    {
        title: "Panel solar 330W",
        description: "Panel solar 330 W Policirstalino Grado A Módulo fotovoltaico",
        price: "3,079",
        image: "https://res.cloudinary.com/ienergy/image/upload/v1618529292/Ing_detalle/proy_eje/1-min_bq5fsa.webp",
        link: "https://articulo.mercadolibre.com.mx/MLM-827935857-panel-solar-330-w-policristalino-grado-a-modulo-fotovoltaico-_JM#position=1&search_layout=grid&type=item&tracking_id=dc24625f-e4a9-4466-90ef-9aa376824534"
    },
    {
        title: "Panel solar 450W",
        description: "Panel solar 450 W Monocristalino Grado A Perc 144 celdas",
        price: "4,840",
        image: "https://res.cloudinary.com/ienergy/image/upload/v1618529292/Ing_detalle/proy_eje/1-min_bq5fsa.webp",
        link: "https://articulo.mercadolibre.com.mx/MLM-851027510-panel-solar-450w-monocristalino-grado-a-perc-144-celdas-_JM#position=2&search_layout=grid&type=item&tracking_id=43798331-e0c4-4a32-aee9-46d7c7e343b8"
    },
    {
        title: "Kit solar 4 paneles 450 W",
        description: "Kit solar 4 paneles 450 W Microinv 1500W Hoymiles 400kWh/bim",
        price: "35,000",
        image: "https://res.cloudinary.com/ienergy/image/upload/v1618529292/Ing_detalle/proy_eje/1-min_bq5fsa.webp",
        link: "https://articulo.mercadolibre.com.mx/MLM-806802554-kit-solar-4-paneles-450w-microinv-1500w-hoymiles-400kwhbim-_JM#position=5&search_layout=grid&type=item&tracking_id=43798331-e0c4-4a32-aee9-46d7c7e343b8"
    },
    {
        title: "Kit solar 4 paneles 450 W",
        description: "Kit solar 4 paneles 450 W Microinv 1500W Hoymiles 400kWh/bim",
        price: "35,000",
        image: "https://res.cloudinary.com/ienergy/image/upload/v1618529292/Ing_detalle/proy_eje/1-min_bq5fsa.webp",
        link: "https://articulo.mercadolibre.com.mx/MLM-806802554-kit-solar-4-paneles-450w-microinv-1500w-hoymiles-400kwhbim-_JM#position=5&search_layout=grid&type=item&tracking_id=43798331-e0c4-4a32-aee9-46d7c7e343b8"
    }
];